import type { BrowserOptions } from "@sentry/browser";

import * as Sentry from "@sentry/browser";

import type { SentryRootProject } from "@/lib/integrations/sentry/sentry";

import { solvariContextIntegration } from "@/lib/integrations/sentry/contextIntegration";
import { ignoreConfig } from "@/lib/integrations/sentry/ignored";
import { getProjectRelease } from "@/lib/integrations/sentry/project";

function init(rootProject: SentryRootProject) {
  Sentry.init(getConfig(rootProject));
}

function makeMicroFrontEndTransport() {
  return Sentry.makeMultiplexedTransport(
    Sentry.makeFetchTransport,
    ({ getEvent }) => {
      if (!window.sentryProjects) {
        return [];
      }
      const projectName = getEvent()?.tags?.project as string | undefined;
      const project = projectName
        ? window.sentryProjects[projectName]
        : undefined;
      return project?.dsn ? [project.dsn] : [];
    },
  );
}

function getConfig(rootProject: SentryRootProject): BrowserOptions {
  return {
    dsn: rootProject.dsn,
    environment: rootProject.environment,
    transport: makeMicroFrontEndTransport(),
    release: getProjectRelease(rootProject),
    integrations: [
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration(),
      Sentry.sessionTimingIntegration(),
      solvariContextIntegration(rootProject),
    ],
    sendDefaultPii: true,
    replaysOnErrorSampleRate: 0.1,
    ...ignoreConfig,
  };
}

export { init };
