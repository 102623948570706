import type { EventHint } from "@sentry/browser";
import type { Event as SentryEvent, Integration } from "@sentry/types";

import { pipe } from "ramda";

import type {
  SentryProject,
  SentryRootProject,
} from "@/lib/integrations/sentry/sentry";

import { getArgusEvents } from "@/lib/argus/client";
import { getClientSideArgusProjection } from "@/lib/argus/client/projections";
import { mergeDeep } from "@/lib/helpers/utils/mergeDeep";
import {
  getEventProject,
  setEventProject,
} from "@/lib/integrations/sentry/project";

const addDataLayerData: SentryProject["addData"] = () => ({
  contexts: { dataLayer: { events: window.dataLayer ?? [] } },
});

const addArgusData: SentryProject["addData"] = () => ({
  contexts: {
    argusEvents: { events: getArgusEvents() },
    metadata: { ...getClientSideArgusProjection() },
  },
});

const applyDataToEvent =
  (hint: EventHint, addData: SentryProject["addData"]) =>
  (event: Readonly<SentryEvent>): SentryEvent => {
    if (!addData) {
      return event;
    }

    return mergeDeep(event, addData(event, hint));
  };

const solvariContextIntegration = (
  rootProject: SentryRootProject,
): Integration => ({
  name: "SolvariContext",
  processEvent(event, hint) {
    if (event.exception?.values?.[0]?.value === "undefined") {
      return null;
    }

    const project = getEventProject(event);
    return pipe(
      (event: SentryEvent) => setEventProject(event, project),
      applyDataToEvent(hint, addDataLayerData),
      applyDataToEvent(hint, addArgusData),
      applyDataToEvent(hint, rootProject.addData),
      applyDataToEvent(hint, project?.addData),
    )(event);
  },
});

export { solvariContextIntegration };
