// See: https://docs.sentry.io/clients/javascript/tips/

export const ignoreConfig = {
  ignoreErrors: [
    // Untraceable error: https://github.com/getsentry/sentry-javascript/issues/3440
    "Non-Error promise rejection captured",
    // Chrome on IOS bug, unfixable https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
    "__gCrWeb",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "https://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "https://loading.retry.widdit.com/",
    "atomicFindClose",
    // Avast extension error
    "_avast_submit",
    "webkit-masked-url",
    "__AutoFillPopupClose__",
    "_AutofillCallbackHandler",

    // TODO remove when upgrading to vue 3, it's a bug in vueUse, but it works at runtime
    "onMounted is called when there is no active component instance to be associated with",
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Browser extensions
    /extension:\/\//,
    // Safari extension bugs: https://github.com/getsentry/sentry-javascript/discussions/5875
    /webkit-masked-url/,
  ],
};
